// src/App.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './App.css';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toast styles
import { countryCodes } from './countryCodes';
import { useNavigate } from 'react-router-dom';

// Brand logo URL (replace with your actual logo)
const logoUrl = 'https://kredmaxx.com/wp-content/uploads/2024/08/algomaxx-470-x-110-px-7.png'; // Add your logo URL here

// Stripe public key
const stripePublicKey = "pk_test_51P5lNvIoboGXb9jKSwe9q2Mq2J4L0wBKWtGbP6zhZtco9at3ivfjpUu2L4M6uJuN5ZEkFGbrKhGInaisaTh0I6yh00rzIpQhlg";
const stripePromise = loadStripe(stripePublicKey);

const plans = [
  { id: '1', name: 'Lifetime Plan', price: 69, description: 'Best for short-term traders' },

];

const API_URL = process.env.REACT_APP_API_URL || 'api'; // Use your backend URL

const App = () => {

  const [selectedPlan, setSelectedPlan] = useState(plans[0]); // Default to the first plan
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phone: '',
    tradingViewId: '',
    country: '',
    state: '',
    postalCode: '',
    address: ''
  });
  const [countryCode, setCountryCode] = useState(countryCodes[0].code); // Default to first country code
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false); // To track if the terms are accepted

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure required fields are not empty and terms are accepted
    if (!userDetails.email || !userDetails.phone || !userDetails.tradingViewId) {
      toast.error('Email, phone number, and TradingView ID are required.');
      return;
    }

    if (userDetails.phone.length !== 10) {
      toast.error('Phone number must be exactly 10 digits.');
      return;
    }

    userDetails.phone = userDetails.country + userDetails.phone;

    if (!termsAccepted) {
      toast.error('You must accept the terms and conditions.');
      return;
    }

    console.log('Form submitted with user details:', userDetails);
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="payment-page">
        {/* Brand Logo */}
        <div className="logo-container">
          <img src={logoUrl} alt="Brand Logo" className="brand-logo" />
        </div>

        <div className="payment-container">
          <ToastContainer />
          {paymentSuccess ? (
            <div className="success-message">
              <h2>Payment Successful!</h2>
              <p>🎉 Congratulations! Your payment for <strong>{selectedPlan.name}</strong> has been successfully processed. Please check your email for further details.</p>
              <button className="dashboard-button" onClick={() => window.location.href = '/dashboard'}>
                Go to Dashboard
              </button>
            </div>
          ) : (
            <form className="user-form" onSubmit={handleSubmit}>
              <div className="customer-info">
                <h3>Customer Information</h3>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-input"
                    placeholder="Full Name"
                    value={userDetails.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="phone-input-group">
                    <select
                      className="form-input country-code-dropdown"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      {countryCodes.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name} ({country.code})
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      name="phone"
                      className="form-input"
                      placeholder="Phone"
                      value={userDetails.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="form-input"
                    placeholder="Email"
                    value={userDetails.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="country"
                    className="form-input"
                    placeholder="Country"
                    value={userDetails.country}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="state"
                    className="form-input"
                    placeholder="State"
                    value={userDetails.state}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="postalCode"
                    className="form-input"
                    placeholder="Postal Code"
                    value={userDetails.postalCode}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="address"
                    className="form-input"
                    placeholder="Address"
                    value={userDetails.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="tradingViewId"
                    className="form-input"
                    placeholder="TradingView ID"
                    value={userDetails.tradingViewId}
                    onChange={handleChange}
                    required
                  />
                </div>

                <h3>Select a Plan</h3>
                <div className="plan-selection">
                  {plans.map((plan) => (
                    <button
                      key={plan.id}
                      className={`plan-btn ${selectedPlan.id === plan.id ? 'active' : ''}`}
                      type="button"
                      onClick={() => setSelectedPlan(plan)}
                    >
                      {plan.name} - ${plan.price}
                    </button>
                  ))}
                </div>

                {/* Terms and Conditions Checkbox */}
                <div className="form-group terms-conditions">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                  <label htmlFor="terms">
                    I accept the <a href="/terms" target="_blank">Terms and Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.
                  </label>
                </div>

                <StripeCheckout
                  userDetails={{ ...userDetails, phone: countryCode + userDetails.phone }}
                  selectedPlan={selectedPlan}
                  setPaymentSuccess={setPaymentSuccess}
                />
              </div>
            </form>
          )}

          {!paymentSuccess && (
            <div className="order-summary">
              <h3>Total Amount</h3>
              <h2>${selectedPlan.price}</h2>
              <p>100% Secure Payment</p>

              <div className="order-details">
                <p><strong>Plan:</strong> {selectedPlan.name}</p>
                <p><strong>Description:</strong> {selectedPlan.description}</p>
                <p><strong>Total:</strong> ${selectedPlan.price}</p>
              </div>
            </div>
          )}

        </div>

        {/* Footer Component */}
        <footer className="footer">
          <p>
            &copy; {new Date().getFullYear()} KredMaxx Technologies FZE. All rights reserved.
            <br />
            <a href="/terms" target="_blank">Terms and Conditions</a> | <a href="/privacy" target="_blank">Privacy Policy</a>
          </p>
        </footer>
      </div>
    </Elements>
  );
};

// Component for Stripe Checkout Form
const StripeCheckout = ({ userDetails, selectedPlan, setPaymentSuccess }) => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      toast.error('Stripe has not loaded yet. Please try again.');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: userDetails.name,
        email: userDetails.email
      }
    });

    if (error) {
      toast.error(`Payment method error: ${error.message}`);
      return;
    }

    try {
      toast.info('Creating payment intent...');

      console.log('Payment method info', paymentMethod);
      const paymentIntentResponse = await axios.post(`${API_URL}/stripe/create-payment-intent`, {
        amount: selectedPlan.price * 100,
        paymentMethodId: paymentMethod.id,
        userDetails,
        plan: selectedPlan,
      });

      const { clientSecret, status } = paymentIntentResponse.data;

      if (status === 'succeeded') {
        toast.success('Payment has already succeeded, skipping confirmation.');
        return;
      }

      toast.info('Confirming payment...');

      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });
      console.log('Stripe confirmCardPayment:', paymentIntent);

      if (confirmError) {
        toast.error(`Error confirming the payment: ${confirmError.message}`);
        return;
      }

      toast.success('Payment successful!');
      setPaymentSuccess(true);

      const response = await axios.post(`${API_URL}/grant-tradingview-access`, {
        userDetails,
        plan: selectedPlan,
        paymentIntentId: paymentIntent.id,
      });

      toast.success(response.data.message);
      navigate('/success');
    } catch (err) {
      toast.error('Backend error occurred.');
    }
  };

  return (
    <div className="payment-section">
      <h3>Payment Information</h3>
      <CardElement className="card-element" />
      <button type="submit" className="form-submit-btn" onClick={handlePaymentSubmit} disabled={!stripe}>
        Pay ${selectedPlan.price}
      </button>
    </div>
  );
};

export default App;